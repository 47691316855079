// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nscreen-cambio-frontend-primary: mat.define-palette(mat.$indigo-palette);
$nscreen-cambio-frontend-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$nscreen-cambio-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nscreen-cambio-frontend-theme: mat.define-light-theme(
  (
    color: (
      cyann: $nscreen-cambio-frontend-primary,
      primary: $nscreen-cambio-frontend-primary,
      accent: $nscreen-cambio-frontend-accent,
      warn: $nscreen-cambio-frontend-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nscreen-cambio-frontend-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif !important;
}

.font-nunito {
  font-family: 'Nunito', sans-serif !important;
}

.mat-tab-labels {
  justify-content: space-between;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element {
  background: #16b8d9 !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element {
  border-color: #16b8d9 !important;
}

.text-navy {
  color: theme('colors.navy') !important;
}

.bg-navy {
  background-color: theme('colors.navy') !important;
}

.border-navy {
  border-color: theme('colors.navy') !important;
}

.text-cyan {
  color: theme('colors.cyan') !important;
}

.bg-cyan {
  background-color: theme('colors.cyan') !important;
}

.border-cyan {
  border-color: theme('colors.cyan') !important;
}

.text-gren-light {
  color: theme('colors.gren-light') !important;
}

.bg-gren-light {
  background-color: theme('colors.gren-light') !important;
}

.border-gren-light {
  border-color: theme('colors.gren-light') !important;
}

.mat-tab-label-active .mat-tab-label-content {
  color: theme('colors.cyan');
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: theme('colors.cyan');
}

.mat-radio-label-content {
  color: theme('colors.gray');
  font-size: 16px;
}

.mat-form-field-outline-end {
  border-top: 1px solid theme('colors.gray-light') !important;
  border-right: 1px solid theme('colors.gray-light') !important;
  border-bottom: 1px solid theme('colors.gray-light') !important;
}

.mat-form-field-outline-start {
  border-top: 1px solid theme('colors.gray-light') !important;
  border-left: 1px solid theme('colors.gray-light') !important;
  border-bottom: 1px solid theme('colors.gray-light') !important;
}

.mat-form-field-outline-gap {
  border-bottom: 1px solid theme('colors.gray-light') !important;
  border-top: 1px solid theme('colors.gray-light') !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-outline-gap {
  border-top-color: transparent !important;
}

.snack-success button {
  color: theme('colors.gren-light');
}

.snack-error,
.snack-success {
  color: white;
}

.snack-error button {
  color: theme('colors.error');
}

.percent .mat-form-field-infix::after {
  content: '%';
  position: absolute;
  right: 10px;
}

.pagination-number, .pagination-action {
  &:hover:enabled {
    color: theme('colors.blue') !important;
    font-weight: bold !important;
    font-size: 16px;
  }
}
